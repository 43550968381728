import React from "react";
import Layout from "../components/Layout";

import i18n from "../i18next";

const NotFoundPage = () => (
  <Layout language="en">
    <section class="hero has-background-light	has-text-centered is-large">
      <div class="hero-body">
        <div class="container">
          <h1 class="title">{i18n.t("notFound.title", { lng: "en" })}</h1>
          <h2 class="subtitle">
            {i18n.t("notFound.description", { lng: "en" })}
          </h2>
        </div>
      </div>
    </section>
  </Layout>
);

export default NotFoundPage;
